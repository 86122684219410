#popup2Display{
    display: none; 
}
#absolute2{
    position: relative; 
}
#congratulations{
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    width: 30%;
    margin-left: 33.5rem; 
    margin-top: -39rem; 
    background: white;
    position: absolute;  
    border: 1px solid black; 
    border-radius: 10px; 
    &__close{
        margin-top: 1rem; 
        margin-bottom: -2rem; 
        margin-left: 25rem;
        cursor: pointer; 
        i{
            color: #00dfe9;
        }
    }
    &__title{
        font-size: 18px; 
    }
    &__text1{
        text-align: center;
        font-size: 14px; 
        font-weight: bold;
        padding-left: 2rem; 
        padding-right: 2rem; 
    }
    &__text2{
        text-align: center;
        font-size: 14px; 
        font-weight: bold;
        padding-left: 2rem; 
        padding-right: 2rem;
    }
    &__discord{
        img{
        margin-top: 0.5rem; 
        margin-bottom: 0.5rem; 
        width: 3.5rem; 
        height: 4rem;                 
        }
    }
}

@media (min-width: 320px) {
    #absolute2{
        position: relative; 
    }
    #congratulations{
        width: 90%;
        margin-left: 2rem; 
        margin-top: 118rem; 
        z-index: 1;
        &__close{
            margin-top: 1.5rem; 
            margin-bottom: -2rem; 
            margin-left: 32rem;
            font-size: 26px;
        }
        &__title{
            font-size: 26px;
            margin-bottom: -1rem;
        }
        &__text1{
            font-size: 22px; ; 
        }
        &__text2{
            font-size: 22px; 
        }
        &__discord{
            img{
            margin-top: 1rem; 
            margin-bottom: 1rem; 
            width: 5rem; 
            height: 5.5rem;                 
            }
        }
    }
}
@media (min-width: 375px) {
    #congratulations{
        width: 90%;
        margin-left: 2rem; 
        margin-top: 109rem; 
        z-index: 1;
    }
}
@media (min-width: 425px) {
    #congratulations{
        width: 88%;
        margin-left: 2.4rem; 
        margin-top: 105.2rem; 
        z-index: 1;
        &__close{
            margin-left: 30rem;
            font-size: 24px;
        }
        &__title{
            font-size: 24px;
        }
        &__text1{
            font-size: 18px; ; 
        }
        &__text2{
            font-size: 18px; 
        }
        &__discord{
            img{
            width: 4.5rem; 
            height: 5rem;                 
            }
        }
    }
    
}
@media (min-width: 576px) {   
    #congratulations{
        width: 85%;
        margin-left: 2.6rem; 
        margin-top: 88.2rem; 
        z-index: 1; 
        &__close{
            margin-left: 27rem;
            font-size: 18px;
        }
        &__title{
            font-size: 19px;
        }
        &__text1{
            font-size: 12px; ; 
        }
        &__text2{
            font-size: 12px; 
        }
        &__discord{
            img{
            width: 3.5rem; 
            height: 4rem;                 
            }
        }
    }
}
@media (min-width: 768px) {
    #congratulations{
        width: 65%;
        margin-left: 8.2rem; 
        margin-top: 85.6rem; 
        z-index: 1; 
        &__close{
            margin-left: 27.5rem;
            font-size: 18px;
            margin-top: 1rem; 
        }
        &__title{
            font-size: 18px;
            margin-bottom: 0rem; 
        }
        &__text1{
            font-size: 12px; ; 
        }
        &__text2{
            font-size: 12px; 
        }
        &__discord{
            img{
            width: 3.5rem; 
            height: 4rem;                 
            }
        }
    }
}
@media (min-width: 992px) { 
    #congratulations{
        width: 46%;
        margin-left: 16.8rem; 
        margin-top: 81rem; 
        z-index: 1;
        &__close{
            margin-left: 25rem;
            font-size: 18px;
            margin-top: 1rem;  
        }
        &__title{
            font-size: 18px;
            margin-bottom: 0rem; 
        }
        &__text1{
            font-size: 12px; ; 
        }
        &__text2{
            font-size: 12px; 
        }
        &__discord{
            img{
            width: 3.5rem; 
            height: 4rem;                 
            }
        }
    }
}
@media (min-width: 1024px) { 
    #congratulations{
        width: 44%;
        margin-left: 18rem; 
        margin-top: 45rem; 
        z-index: 1;
        &__close{
            margin-left: 25rem;
            font-size: 18px;
            margin-top: 1rem;  
        }
        &__title{
            font-size: 18px;
            margin-bottom: 0rem; 
        }
        &__text1{
            font-size: 12px; ; 
        }
        &__text2{
            font-size: 12px; 
        }
        &__discord{
            img{
            width: 3.5rem; 
            height: 4rem;                 
            }
        }
    }
}
@media (min-width: 1200px) {
    #congratulations{
        width: 42%;
        margin-left: 21.5rem; 
        margin-top: 46.7rem; 
        z-index: 1; 
        &__close{
            margin-left: 27rem;
            font-size: 20px;
            margin-top: 1rem;  
        }
        &__title{
            font-size: 20px;
            margin-bottom: 0.3rem; 
        }
        &__text1{
            font-size: 13px; ; 
        }
        &__text2{
            font-size: 13px; 
        }
        &__discord{
            img{
            width: 3.5rem; 
            height: 4rem;                 
            }
        }
    }
}
@media (min-width: 1400px) {
    #congratulations{
        width: 40%;
        margin-left: 26.5rem; 
        margin-top: 50.8rem; 
        z-index: 1; 
        &__close{
            margin-left: 30rem;
            font-size: 24px;
            margin-top: 1rem; 
        }
        &__title{
            font-size: 21px;
            margin-bottom: 0.3rem; 
        }
        &__text1{
            font-size: 15px; ; 
        }
        &__text2{
            font-size: 15px; 
        }
        &__discord{
            img{
            width: 4rem; 
            height: 4.5rem;                 
            }
        }
    }
}
@media (min-width: 1600px) {
    #congratulations{
        width: 38%;
        margin-left: 31rem; 
        margin-top: 57rem; 
        z-index: 1;  
        &__close{
            margin-left: 33rem;
            font-size: 26px;
            margin-top: 1rem;
        }
        &__title{
            font-size: 24px;
            margin-bottom: 0.3rem; 
        }
        &__text1{
            font-size: 17px; ; 
        }
        &__text2{
            font-size: 17px; 
        }
        &__discord{
            img{
            width: 4.5rem; 
            height: 5rem;                 
            }
        }
    }
}
@media (min-width: 1800px) {
    #congratulations{
        width: 38%;
        margin-left: 34.5rem; 
        margin-top: 62.4rem; 
        z-index: 1;  
        &__close{
            margin-left: 37rem;
            font-size: 29px;
            margin-top: 1.5rem;
        }
        &__title{
            font-size: 27px;
            margin-bottom: 0.3rem;
        }
        &__text1{
            font-size: 20px; ; 
        }
        &__text2{
            font-size: 20px; 
        }
        &__discord{
            img{
            width: 5rem; 
            height: 5.5rem;                 
            }
        }
    }
}
@media (min-width: 1920px) {
    #congratulations{
        width: 38%;
        margin-left: 37rem; 
        margin-top: 75.7rem; 
        z-index: 1;  
        &__close{
            margin-left: 39rem;
            font-size: 32px;
            margin-top: 1.5rem;
        }
        &__title{
            font-size: 30px;
            margin-bottom: 0.3rem; 
        }
        &__text1{
            font-size: 23px; ; 
        }
        &__text2{
            font-size: 23px; 
        }
        &__discord{
            img{
            width: 5.5rem; 
            height: 6rem;                 
            }
        }
    }
}
@media (min-width: 2560px) {
    #congratulations{
        width: 40%;
        margin-left: 48rem; 
        margin-top: 99.8rem; 
        z-index: 1; 
        &__close{
            margin-left: 56rem;
            font-size: 46px;
            margin-top: 1.5rem;
            margin-bottom: -4rem; 
        }
        &__title{
            font-size: 42px;
            margin-bottom: -0.5rem; 
        }
        &__text1{
            font-size: 30px; 
            margin-bottom: 0.5rem; 
        }
        &__text2{
            font-size: 30px; 
            margin-bottom: 0.5em; 
        }
        &__discord{
            margin-bottom: 1rem;    
            img{
            width: 7.5rem; 
            height: 8rem;              
            }
        }
    }
}

@media (min-width: 3456px) {
    #congratulations{
        width: 40%;
        margin-left: 64rem; 
        margin-top: 146.2rem; 
        z-index: 1; 
        &__close{
            margin-left: 76rem;
            font-size: 64px;
            margin-top: 2.5rem;
            margin-bottom: -6rem; 
        }
        &__title{
            font-size: 60px;
            margin-bottom: -1rem; 
        }
        &__text1{
            font-size: 45px; 
            margin-bottom: 0.5rem; 
        }
        &__text2{
            font-size: 45px; 
            margin-bottom: 0.5em; 
        }
        &__discord{
            margin-bottom: 2rem;    
            img{
                width: 11rem; 
                height: 11.5rem;              
            }
        }
    }
}

@media (min-width: 3840px) {
    #congratulations{
        width: 40%;
        margin-left: 71rem; 
        margin-top: 144.2rem; 
        z-index: 1; 
        &__close{
            margin-left: 86rem;
            font-size: 68px;
            margin-top: 2.5rem;
            margin-bottom: -6rem; 
        }
        &__title{
            font-size: 62px;
            margin-bottom: -1rem; 
        }
        &__text1{
            font-size: 48px; 
            margin-bottom: 0.5rem; 
        }
        &__text2{
            font-size: 48px; 
            margin-bottom: 0.5em; 
        }
        &__discord{
            margin-bottom: 2rem;    
            img{
                width: 11.5rem; 
                height: 12rem;              
            }
        }
    }
}