#landingPage{
    position: relative; 
    &__header{
        display: flex; 
        flex-direction: row;  
        align-items: center;
        margin-top: 2rem;
    }
    &__left{
        width: 50%; 
        margin-left: 4rem; 
        margin-top: -1rem; 
    }
    &__title{
        margin-left: 1.6rem; 
        img{
            width: 30rem; 
            height: auto;
            cursor: pointer;  
        } 
    }
    &__subtitle{
        margin-left: 4rem; 
        margin-top: -2rem; 
        margin-bottom: 3rem; 
        color: rgb(153, 153, 153); 
        font-size: 16px; 
    }
    &__text1{
        margin-left: 4rem;  
        font-size: 14px; 
    }
    &__text1--top{
        margin-bottom: 3rem; 
        p{
            margin-bottom: -1rem; 
        }
    }
    &__text1--middle{
        margin-bottom: 2rem; 
    }
    &__text1--down{
        margin-bottom: 2rem; 
    }
    &__text2{
        margin-left: 4rem; 
        font-size: 20px; 
    }
    &__text3{
        margin-left: 4rem; 
        font-size: 20px; 
    }
    &__discord{
        img{
        margin-left: 4rem; 
        width: 3.5rem; 
        height: 4rem;                 
        }
    }
    &__right{
        width: 50%; 
        margin-left: 2rem; 
        video{
            width: 40rem; 
            height: 40rem; 
        }
    }
    &__main{
        display: flex; 
        flex-direction: column; 
        align-items: center; 
    }
    &__open{
        font-size: 24px;
    }
    &__timer{
        font-size: 30px; 
        color: #00dfe9;
        margin-top: -3rem; 
    }
    &__access{
        font-size: 22px;
        margin-bottom: 0.5rem; 
    }
    &__buttons{
        display: flex; 
        flex-direction: row; 
    }
    &__nft{
        display: flex; 
        flex-direction: column; 
        align-items: center;
    }
    // &__subscription{
    //     display: flex; 
    //     flex-direction: column; 
    //     align-items: center;
    // }
    &__button1{
        button{
        background: transparent linear-gradient(90deg, #c6c5f5 30%, #00dfe9 100%) 0% 0% no-repeat padding-box;
        color: white; 
        border: none; 
        border-radius: 30px; 
        font-size: 18px; 
        width: 16rem; 
        height: 3.5rem; 
        cursor: pointer; 
        }
    }
	&__button2{
        button{
        background: transparent linear-gradient(90deg, #c6c5f5 30%, #00dfe9 100%) 0% 0% no-repeat padding-box;
        color: white; 
        border: none; 
        border-radius: 30px; 
        font-size: 18px; 
        width: 16rem; 
        height: 3.5rem; 
        cursor: pointer; 
        }
    }
    // &__button2{ 
    //     button{
    //     background: transparent linear-gradient(90deg, #c6c5f5 30%, #00dfe9 100%) 0% 0% no-repeat padding-box;
    //     color: white; 
    //     border: none; 
    //     border-radius: 30px; 
    //     font-size: 18px; 
    //     width: 16rem; 
    //     height: 3.5rem; 
    //     margin-left: 2rem; 
    //     cursor: pointer; 
    //     }
    // }
    &__matic{
        font-size: 12px; 
        font-weight: bold; 
    }
    // &__euro{
    //     font-size: 12px; 
    //     font-weight: bold;
    //     margin-left: 2rem; 
    // }
    &__conditions{
        text-align: center;
        font-weight: bold; 
        font-size: 14px; 
        margin-left: 20rem; 
        margin-right: 20rem; 
        margin-top: 1rem; 
    }
    &__line{
        height: 3px; 
        width: 40%; 
        margin-top: 3rem; 
        background: transparent linear-gradient(90deg, #c6c5f5 30%, #00dfe9 100%) 0% 0% no-repeat padding-box;
    }
    &__portfolio{
        font-size: 18px; 
        margin-top: 1rem; 
    }
    
    &__artists {
        display: flex;
        flex-direction: column;
        text-align: center; 
    }

    &__links {
        padding-top: 1rem; 
        margin-right: 5rem; 
        margin-left: 5rem; 
        a {
            text-decoration: none;
            color: black;
            font-size: 60px;
            font-weight: bold;
            word-break: break-all;
        }
    }

    &__footer{
        display: flex; 
        flex-direction: row; 
        justify-content: space-around;
        background-color: #00dfe9;
        padding-top: 1rem; 
        padding-bottom: 1rem; 
        margin-top: 3rem; 
    }
    &__721{
        color: white; 
        margin-top: -1.8rem; 
    }
    &__721--title{
        font-size: 26px; 
        margin-bottom: -1rem; 
    }
    &__721--line{
        height: 2.5px; 
        width: 60%; 
        background: white;
        margin-top: -2.2rem; 
        margin-bottom: 1rem; 
    }
    &__721--description{
        font-size: 16px;
    }
    &__contact{
        list-style: none; 
        padding-bottom: 0.5rem; 
        cursor: pointer;
        a{
            text-decoration: none;
            color: white; 
        }
    }
    &__whitepaper{
        list-style: none; 
        padding-bottom: 0.5rem; 
        cursor: pointer;
        a{
            text-decoration: none;
            color: white; 
        }
    }
    &__symbol1{
        width: 8rem; 
        height: auto; 
    }
    &__symbol2{
        width: 15rem; 
        height: auto; 
        margin-left: -5rem; 
        margin-top: 3rem; 
    }
}



@media (min-width: 320px) {
    #landingPage{
        display: flex;
        flex-direction: column;
        &__header{
        display: flex; 
        flex-direction: column; 
        align-items: flex-start;
        margin-left: -4.5rem;
        margin-top: 4rem;  
        }
        &__left{
            width: 80%; 
        }
        &__title{
            img{
                width: 40rem; 
                margin-left: -0.8rem; 
            } 
        }
        &__subtitle{ 
            font-size: 24px;
            margin-top: -2.8rem;  
            margin-left: 3.8rem; 
        }
        &__text1{ 
            font-size: 21px; 
            margin-top: 1rem; 
        }
        &__text1--top{
            margin-bottom: 3rem; 
        }
        &__text1--middle{
            margin-bottom: 2rem; 
        }
        &__text1--down{
            margin-bottom: 2rem; 
        }
        &__text2{ 
            font-size: 30px; 
        }
        &__text3{
            font-size: 30px; 
        }
        &__discord{ 
            img{ 
            width: 4.5rem; 
            height: 5rem;                 
            }
        }
        &__right{
            width: 50%; 
            margin-left: 4.6rem; 
            margin-top: -0.5rem; 
            video{
                width: 40rem; 
                height: 40rem; 
            }
        }
        &__main{
            margin-top: 2rem; 
        }
        &__open{
            font-size: 36px;
			margin-bottom: -1rem; 
        }
        &__timer{
            font-size: 42px; 
            margin-top: -4rem; 
        }
        &__access{
            font-size: 32px;; 
        }
        &__button1{
            button{ 
            font-size: 32px; 
            width: 28rem; 
            height: 5.5rem; 
            border-radius: 45px; 
            cursor: pointer; 
            }
        }
		&__button2{
            button{ 
            font-size: 32px; 
            width: 14rem; 
            height: 5.5rem; 
            border-radius: 45px; 
			margin-top: 1rem; 
            cursor: pointer; 
            }
        }
        &__matic{
            font-size: 20px; 
        }
        &__conditions{
            font-size: 20px; 
            margin-left: 5rem; 
            margin-right: 5rem; 
        }
        &__line{
            height: 5px; 
            width: 65%; 
        }
        &__portfolio{
            font-size: 26px; 
            margin-top: 1rem; 
        }
        &__artists {
            display: flex;
            flex-direction: column;
            text-align: center; 
            a{
            font-size: 4.2rem; 
            }
        }
        &__links{
            padding-top: 3rem; 
        }
        &__721--title{
            font-size: 30px; 
            margin-bottom: -2.5rem; 
            margin-top: -1rem; 
        }
        &__721--description{
            font-size: 20px;
        }
        &__symbol1{
            width: 6rem; 
            height: auto; 
            margin-top: 0.8rem; 
        }
        &__symbol2{
            width: 13rem; 
            height: auto; 
            margin-left: -2rem;  
        }
    }
}
@media (min-width: 375px) {
    #landingPage{
        &__title{
            img{
                width: 38rem; 
                margin-left: -0.7rem; 
            } 
        }
        &__subtitle{ 
            font-size: 20px;
        }
        &__text1{ 
            font-size: 19px; 
        }
        &__text2{ 
            font-size: 27px; 
        }
        &__text3{
            font-size: 27px; 
        }
        &__discord{ 
            img{ 
            width: 4.5rem; 
            height: 5rem;                 
            }
        }
        &__artists {
            display: flex;
            flex-direction: column;
            text-align: center; 
            a{
            font-size: 4.0rem; 
            }
        }
		&__button2{
            button{ 
            font-size: 32px; 
            width: 14rem; 
            height: 5.5rem; 
            border-radius: 45px; 
			margin-top: 1rem; 
            cursor: pointer; 
            }
        }
    }
}
    
@media (min-width: 425px) {
    #landingPage{
        &__title{
            img{
                width: 36rem; 
                margin-left: -0.5rem; 
            } 
        }
        &__subtitle{ 
            font-size: 22px;
            margin-top: -2.8rem;  
            margin-left: 3.8rem; 
        }
        &__text1{ 
            font-size: 18px; 
        }
        &__text2{ 
            font-size: 25px; 
        }
        &__text3{
            font-size: 25px; 
        }
        &__discord{ 
            img{ 
            width: 4rem; 
            height: 4.5rem;                 
            }
        }
        &__open{
            font-size: 32px;
        }
        &__timer{
            font-size: 38px; 
            margin-top: -3rem; 
        }
        &__access{
            font-size: 28px;; 
        }
        &__button1{
            button{ 
            font-size: 30px; 
            width: 25rem; 
            height: 5rem; 
            border-radius: 45px; 
            }
        }
		&__button2{
            button{ 
            font-size: 30px; 
            width: 12rem; 
            height: 5rem; 
            border-radius: 45px; 
			margin-top: 1rem; 
            cursor: pointer; 
            }
        }
        &__matic{
            font-size: 16px; 
        }
        &__conditions{
            font-size: 17px; 
            margin-left: 5rem; 
            margin-right: 5rem; 
        }
        &__line{
            height: 4px; 
            width: 60%; 
        }
        &__portfolio{
            font-size: 24px; 
            margin-top: 1rem; 
        }
        &__721--title{
            font-size: 26px; 
            margin-top: 1rem; 
        }
        &__721--description{
            font-size: 18px;
        }
        &__symbol1{
            width: 7rem; 
            height: auto; 
            margin-top: 0.8rem; 
        }
        &__symbol2{
            width: 13rem; 
            height: auto; 
            margin-left: -2rem;  
        }
    }
}
@media (min-width: 576px) {   
    #landingPage{
        &__title{
            img{
            width: 32rem; 
            margin-left: -0.2rem; 
            } 
        }
        &__subtitle{ 
            font-size: 18px;
            margin-top: -2.5rem;  
            margin-left: 3.9rem; 
        }
        &__text1{ 
            margin-top: -0.5rem; 
            font-size: 13px; 
        }
        &__text1--top{
            margin-bottom: 3rem; 
            p{
                margin-bottom: -0.8rem; 
            }
        }
        &__text2{ 
            font-size: 20px; 
        }
        &__text3{
            font-size: 20px; 
        }
        &__discord{ 
            img{ 
            width: 3.5rem; 
            height: 4rem;                 
            }
        }
        &__right{ 
            margin-left: 5rem; 
            video{
                width: 35rem; 
                height: 35rem; 
            }
        }
        &__main{
            margin-top: 2rem; 
        }
        &__open{
            font-size: 24px;
			margin-bottom: -0.5rem; 
        }
        &__timer{
            font-size: 30px; 
            margin-top: -3.5rem; 
        }
        &__access{
            font-size: 20px;; 
        }
        &__button1{
            button{ 
            font-size: 21px; 
            width: 18rem; 
            height: 4rem; 
            }
        }
		&__button2{
            button{ 
            font-size: 21px; 
            width: 8rem; 
            height: 4rem; 
            border-radius: 45px; 
			margin-top: 1rem; 
            cursor: pointer; 
            }
        }
        &__matic{
            font-size: 12px; 
        }
        &__conditions{
            font-size: 13px; 
            margin-left: 5rem; 
            margin-right: 5rem; 
        }
        &__line{
            height: 3px; 
            width: 60%; 
        }
        &__portfolio{
            font-size: 20px; 
            margin-top: 1rem; 
        }
        &__artists {
            display: flex;
            flex-direction: column;
            text-align: center; 
            a{
            font-size: 3.4rem; 
            }
        }
        &__721--title{
            font-size: 26px; 
            margin-bottom: -2rem; 
            margin-top: 0rem; 
        }
        &__721--description{
            font-size: 14px;
        }
        &__symbol1{
            width: 6rem; 
            height: auto; 
            margin-top: 0.8rem; 
        }
        &__symbol2{
            width: 10rem; 
            height: auto; 
            margin-left: -3rem;  
        }
    }
}
@media (min-width: 768px) {
    #landingPage{
        &__title{
            img{
            width: 30rem; 
            margin-left: 0rem; 
            } 
        }
        &__subtitle{ 
            font-size: 16px;
            margin-top: -2.5rem;  
            margin-left: 3.9rem; 
        }
        &__text1{ 
            margin-top: -0.5rem; 
            font-size: 13px; 
        }
        &__text1--top{
            margin-bottom: 3rem; 
            p{
                margin-bottom: -0.7rem; 
            }
        }
        &__text2{ 
            font-size: 20px; 
        }
        &__text3{
            font-size: 19px; 
        }
        &__discord{ 
            img{ 
            width: 3.5rem; 
            height: 4rem;                 
            }
        }
        &__right{ 
            margin-left: 8.5rem; 
            video{
                width: 40rem; 
                height: 40rem; 
            }
        }
        &__main{
            margin-top: 2rem; 
        }
        &__open{
            font-size: 22px;
			margin-bottom: -0rem; 
        }
        &__timer{
            font-size: 28px; 
            margin-top: -3.5rem; 
        }
        &__access{
            font-size: 18px;; 
        }
        &__button1{
            button{ 
            font-size: 20px; 
            width: 16.5rem; 
            height: 3.5rem; 
            }
        }
		&__button2{
            button{ 
            font-size: 20px; 
            width: 8rem; 
            height: 3.5rem; 
            border-radius: 45px; 
			margin-top: 1rem; 
            cursor: pointer; 
            }
        }
        &__matic{
            font-size: 11px; 
        }
        &__conditions{
            font-size: 12px; 
            margin-left: 5rem; 
            margin-right: 5rem; 
        }
        &__line{
            height: 2.5px; 
            width: 60%; 
        }
        &__portfolio{
            font-size: 19px; 
            margin-top: 1rem; 
        }
        &__artists {
            display: flex;
            flex-direction: column;
            text-align: center; 
            a{
            font-size: 3.2rem; 
            }
        }
        &__721--title{
            font-size: 26px; 
            margin-bottom: -2rem; 
            margin-top: 0rem; 
        }
        &__721--description{
            font-size: 14px;
        }
        &__symbol1{
            width: 7rem; 
            height: auto; 
            margin-top: 0.8rem; 
        }
        &__symbol2{
            width: 12rem; 
            height: auto; 
            margin-left: -3rem;  
        }
    }
}
@media (min-width: 992px) { 
    #landingPage{
            &__title{
                img{
                width: 30rem; 
                margin-left: 0rem; 
                } 
            }
            &__subtitle{ 
                font-size: 16px;
                margin-top: -2.5rem;  
                margin-left: 3.9rem; 
            }
            &__text1{ 
                margin-top: -0.5rem; 
                font-size: 14px; 
            }
            &__text2{ 
                font-size: 21px; 
            }
            &__text3{
                font-size: 20px; 
            }
            &__discord{ 
                img{ 
                width: 3.5rem; 
                height: 4rem;                 
                }
            }
            &__right{ 
                margin-left: 15.5rem; 
                margin-top: -4rem; 
                video{
                    width: 40rem; 
                    height: 40rem; 
                }
            }
            &__main{
                margin-top: 1rem; 
            }
            &__open{
                font-size: 22px;
            }
            &__timer{
                font-size: 30px; 
                margin-top: -3rem; 
            }
            &__access{
                font-size: 18px;; 
            }
            &__button1{
                button{ 
                font-size: 20px; 
                width: 17rem; 
                height: 3.2rem; 
                }
            }
			&__button2{
				button{ 
				font-size: 20px; 
				width: 8rem; 
				height: 3.2rem; 
				border-radius: 45px; 
				margin-top: 1rem; 
				cursor: pointer; 
				}
			}
            &__matic{
                font-size: 12px; 
            }
            &__conditions{
                font-size: 13px; 
                margin-left: 10rem; 
                margin-right: 10rem; 
            }
            &__line{
                height: 3px; 
                width: 50%; 
            }
            &__portfolio{
                font-size: 19px; 
                margin-top: 1rem; 
            }
            &__artists {
                display: flex;
                flex-direction: column;
                text-align: center; 
                a{
                font-size: 3.4rem; 
                }
            }
            &__721--title{
                font-size: 26px; 
                margin-bottom: -2rem; 
                margin-top: 1.5rem; 
            }
            &__721--description{
                font-size: 15px;
            }
            &__symbol1{
                width: 7rem; 
                height: auto; 
                margin-top: 0.8rem; 
            }
            &__symbol2{
                width: 13rem; 
                height: auto; 
                margin-left: -5rem;  
            }
    }
}
@media (min-width: 1024px) { 
    #landingPage{
        &__header{
        display: flex; 
        flex-direction: row; 
        align-items: flex-start;
        margin-left: -4.5rem;
        margin-top: 4rem;  
        }
        &__left{
            width: 50%; 
        }
        &__title{
            img{
            width: 28rem; 
            margin-left: 0.2rem; 
            } 
        }
        &__subtitle{ 
            font-size: 15px;
            margin-top: -2.2rem;  
            margin-left: 3.9rem; 
        }
        &__text1{ 
            margin-top: -0.5rem; 
            font-size: 13px; 
        }
        &__text2{ 
            font-size: 19px; 
        }
        &__text3{
            font-size: 18px; 
        }
        &__discord{ 
            img{ 
            width: 3rem; 
            height: 3.5rem;                 
            }
        }
        &__right{ 
            margin-left: 0rem; 
            margin-top: -1rem; 
            video{
                width: 32rem; 
                height: 32rem; 
            }
        }
        &__main{
            margin-top: 1rem; 
        }
        &__open{
            font-size: 22px;
			margin-bottom: -0.5rem; 
        }
        &__timer{
            font-size: 30px; 
            margin-top: -3rem; 
        }
        &__access{
            font-size: 18px;; 
        }
        &__button1{
            button{ 
            font-size: 20px; 
            width: 17rem; 
            height: 3.2rem; 
            }
        }
		&__button2{
			button{ 
			font-size: 20px; 
			width: 8rem; 
			height: 3.2rem; 
			border-radius: 45px; 
			margin-top: 1rem; 
			cursor: pointer; 
			}
		}
        &__matic{
            font-size: 11px; 
        }
        &__conditions{
            font-size: 12px; 
            margin-left: 10rem; 
            margin-right: 10rem; 
        }
        &__line{
            height: 3px; 
            width: 50%; 
        }
        &__portfolio{
            font-size: 19px; 
            margin-top: 1rem; 
        }
        &__artists {
            display: flex;
            flex-direction: column;
            text-align: center; 
            a{
            font-size: 3.5rem; 
            }
        }
        &__721--title{
            font-size: 26px; 
            margin-bottom: -2rem; 
            margin-top: 1.5rem; 
        }
        &__721--description{
            font-size: 14px;
        }
        &__symbol1{
            width: 7rem; 
            height: auto; 
            margin-top: 0.8rem; 
        }
        &__symbol2{
            width: 13rem; 
            height: auto; 
            margin-left: -5rem;  
        }
    }
}
@media (min-width: 1200px) {
    #landingPage{
        &__header{
            display: flex; 
            flex-direction: row; 
            align-items: flex-start;
            margin-left: -4.5rem;
            margin-top: 4rem;  
            }
            &__left{
                width: 50%; 
            }
            &__title{
                img{
                width: 30rem; 
                margin-left: -0rem; 
                } 
            }
            &__subtitle{ 
                font-size: 16px;
                margin-top: -2.2rem;  
                margin-left: 3.9rem; 
            }
            &__text1{ 
                margin-top: -0.5rem; 
                font-size: 14px; 
            }
            &__text2{ 
                font-size: 20px; 
            }
            &__text3{
                font-size: 19px; 
            }
            &__discord{ 
                img{ 
                width: 3.5rem; 
                height: 4rem;                 
                }
            }
            &__right{ 
                margin-left: -0rem; 
                margin-top: -2.5rem; 
                video{
                    width: 38rem; 
                    height: 38rem; 
                }
            }
            &__main{
                margin-top: 1rem; 
            }
            &__open{
                font-size: 25px;
				margin-bottom: -0.8rem; 
            }
            &__timer{
                font-size: 34px; 
                margin-top: -3rem; 
            }
            &__access{
                font-size: 22px;; 
            }
            &__button1{
                button{ 
                font-size: 22px; 
                width: 18.5rem; 
                height: 3.6rem; 
                }
            }
			&__button2{
				button{ 
				font-size: 22px; 
				width: 9rem; 
				height: 3.6rem; 
				border-radius: 45px; 
				margin-top: 1rem; 
				cursor: pointer; 
				}
			}
            &__matic{
                font-size: 12px; 
            }
            &__conditions{
                font-size: 13px; 
                margin-left: 12rem; 
                margin-right: 12rem; 
            }
            &__line{
                height: 3px; 
                width: 50%; 
            }
            &__portfolio{
                font-size: 21px; 
                margin-top: 1rem; 
            }
            &__artists {
                display: flex;
                flex-direction: column;
                text-align: center; 
                a{
                font-size: 3.8rem; 
                }
            }
            &__721--title{
                font-size: 28px; 
                margin-bottom: -2rem; 
                margin-top: 1.5rem; 
            }
            &__721--description{
                font-size: 16px;
            }
            &__symbol1{
                width: 8rem; 
                height: auto; 
                margin-top: 0.8rem; 
            }
            &__symbol2{
                width: 16rem; 
                height: auto; 
                margin-left: -5rem; 
                margin-top: 3.5rem;  
            }
    }
}
@media (min-width: 1400px) {
    #landingPage{
        &__header{
            display: flex; 
            flex-direction: row; 
            align-items: flex-start;
            margin-left: -4.5rem;
            margin-top: 4rem;  
            }
            &__left{
                width: 50%; 
            }
            &__title{
                img{
                width: 32rem; 
                margin-left: -0rem; 
                } 
            }
            &__subtitle{ 
                font-size: 18px;
                margin-top: -2.2rem;  
                margin-left: 3.9rem; 
            }
            &__text1{ 
                margin-top: -0.5rem; 
                font-size: 16px; 
            }
            &__text2{ 
                font-size: 22px; 
            }
            &__text3{
                font-size: 21px; 
            }
            &__discord{ 
                img{ 
                width: 4rem; 
                height: 4.5rem;                 
                }
            }
            &__right{ 
                margin-left: -1rem; 
                margin-top: -2.5rem; 
                video{
                    width: 43rem; 
                    height: 43rem; 
                }
            }
            &__main{
                margin-top: 1rem; 
            }
            &__open{
                font-size: 28px;
				margin-bottom: -1rem; 
            }
            &__timer{
                font-size: 36px; 
                margin-top: -3rem; 
            }
            &__access{
                font-size: 24px;; 
            }
            &__button1{
                button{ 
                font-size: 25px; 
                width: 21rem; 
                height: 4rem; 
                }
            }
			&__button2{
				button{ 
				font-size: 25px; 
				width: 10rem; 
				height: 4rem; 
				border-radius: 45px; 
				margin-top: 1rem; 
				cursor: pointer; 
				}
			}
            &__matic{
                font-size: 14px; 
            }
            &__conditions{
                font-size: 15px; 
                margin-left: 14rem; 
                margin-right: 14rem; 
            }
            &__line{
                height: 4px; 
                width: 50%; 
            }
            &__portfolio{
                font-size: 23px; 
                margin-top: 1rem; 
            }
            &__artists {
                display: flex;
                flex-direction: column;
                text-align: center; 
                a{
                font-size: 4.1rem; 
                }
            }
            &__721--title{
                font-size: 31px; 
                margin-bottom: -2rem; 
                margin-top: 1.5rem; 
            }
            &__721--description{
                font-size: 19px;
            }
            &__symbol1{
                width: 9rem; 
                height: auto; 
                margin-top: 0.8rem; 
            }
            &__symbol2{
                width: 19rem; 
                height: auto; 
                margin-left: -5rem;  
                margin-top: 4rem; 
            }
    }
}
@media (min-width: 1600px) {
#landingPage{
    &__header{
        display: flex; 
        flex-direction: row; 
        align-items: flex-start;
        margin-left: -3rem;
        margin-top: 4rem;  
        }
        &__left{
            width: 50%; 
            margin-top: 1rem; 
        }
        &__title{
            img{
            width: 35rem; 
            margin-left: -0.3rem; 
            } 
        }
        &__subtitle{ 
            font-size: 20px;
            margin-top: -2.5rem;  
            margin-left: 3.9rem; 
        }
        &__text1{ 
            margin-top: -0.5rem; 
            font-size: 18px; 
        }
        &__text2{ 
            font-size: 26px; 
        }
        &__text3{
            font-size: 25px; 
        }
        &__discord{ 
            img{ 
            width: 4.5rem; 
            height: 5rem;                 
            }
        }
        &__right{ 
            margin-left: -0rem; 
            margin-top: -2.5rem; 
            video{
                width: 48rem; 
                height: 48rem; 
            }
        }
        &__main{
            margin-top: 1rem; 
        }
        &__open{
            font-size: 32px;
			margin-bottom: -1.2rem; 
        }
        &__timer{
            font-size: 40px; 
            margin-top: -3rem; 
        }
        &__access{
            font-size: 28px;; 
        }
        &__button1{
            button{ 
            font-size: 27px; 
            width: 23rem; 
            height: 4.5rem; 
            }
        }
		&__button2{
			button{ 
			font-size: 27px; 
			width: 10rem; 
			height: 4.5rem; 
			border-radius: 45px; 
			margin-top: 1rem; 
			cursor: pointer; 
			}
		}
        &__matic{
            font-size: 16px; 
        }
        &__conditions{
            font-size: 18px; 
            margin-left: 15rem; 
            margin-right: 15rem; 
        }
        &__line{
            height: 4px; 
            width: 50%; 
        }
        &__portfolio{
            font-size: 25px; 
            margin-top: 1rem; 
        }
        &__artists {
            display: flex;
            flex-direction: column;
            text-align: center; 
            a{
            font-size: 4.6rem; 
            }
        }
        &__721--title{
            font-size: 33px; 
            margin-bottom: -2.5rem; 
            margin-top: 1.5rem; 
        }
        &__721--description{
            font-size: 21px;
        }
        &__symbol1{
            width: 10rem; 
            height: auto; 
            margin-top: 0.8rem; 
        }
        &__symbol2{
            width: 20rem; 
            height: auto; 
            margin-left: -5rem;  
            margin-top: 4rem; 
        }
    }
}
@media (min-width: 1800px) {
#landingPage{
    &__header{
        display: flex; 
        flex-direction: row; 
        align-items: flex-start;
        margin-left: -2.5rem;
        margin-top: 4rem;  
        }
        &__left{
            width: 50%; 
            margin-top: 1rem; 
        }
        &__title{
            img{
            width: 40rem; 
            margin-left: -0.8rem; 
            } 
        }
        &__subtitle{ 
            font-size: 24px;
            margin-top: -3rem;  
            margin-left: 3.9rem; 
        }
        &__text1{ 
            margin-top: -0.5rem; 
            font-size: 20px; 
        }
        &__text2{ 
            font-size: 29px; 
        }
        &__text3{
            font-size: 28px; 
        }
        &__discord{ 
            img{ 
            width: 5rem; 
            height: 5.5rem;                 
            }
        }
        &__right{ 
            margin-left: -0rem; 
            margin-top: -2.5rem; 
            video{
                width: 54rem; 
                height: 54rem; 
            }
        }
        &__main{
            margin-top: 1rem; 
        }
        &__open{
            font-size: 36px;
			margin-bottom: -1.4rem; 
        }
        &__timer{
            font-size: 46px; 
            margin-top: -4rem; 
        }
        &__access{
            font-size: 30px;; 
        }
        &__button1{
            button{ 
            font-size: 30px; 
            width: 25rem; 
            height: 5.2rem; 
            }
        }
		&__button2{
			button{ 
			font-size: 30px; 
			width: 12rem; 
			height: 5.2rem; 
			border-radius: 45px; 
			margin-top: 1rem; 
			cursor: pointer; 
			}
		}
        &__matic{
            font-size: 18px; 
        }
        &__conditions{
            font-size: 20px; 
            margin-left: 17rem; 
            margin-right: 17rem; 
        }
        &__line{
            height: 4.5px; 
            width: 50%; 
        }
        &__portfolio{
            font-size: 28px; 
            margin-top: 1rem; 
        }
        &__artists {
            display: flex;
            flex-direction: column;
            text-align: center; 
            a{
            font-size: 5rem; 
            }
        }
        &__721--title{
            font-size: 38px; 
            margin-bottom: -3.2rem; 
            margin-top: 1.5rem; 
        }
        &__721--description{
            font-size: 23px;
        }
        &__symbol1{
            width: 11rem; 
            height: auto; 
            margin-top: 0.8rem; 
        }
        &__symbol2{
            width: 22rem; 
            height: auto; 
            margin-left: -10rem;  
            margin-top: 5rem; 
        }
    }
}
@media (min-width: 1920px) {
#landingPage{
    &__header{
        display: flex; 
        flex-direction: row; 
        align-items: flex-start;
        margin-left: -2.5rem;
        margin-top: 4rem;  
        }
        &__left{
            width: 50%; 
            margin-top: 1rem; 
        }
        &__title{
            img{
            width: 44rem; 
            margin-left: -1.1rem; 
            } 
        }
        &__subtitle{ 
            font-size: 27px;
            margin-top: -3rem;  
            margin-left: 3.9rem; 
            margin-bottom: 4rem; 
        }
        &__text1{ 
            margin-top: -0.5rem; 
            font-size: 24px; 
            margin-bottom: 4rem; 
            p{
                margin-bottom: -1.5rem; 
            }
        }
        &__text1--top{
            margin-bottom: 4rem; 
        }
        &__text1--middle{
            margin-bottom: 4rem; 
        }
        &__text1--down{
            margin-bottom: 5rem; 
        }
        &__text2{ 
            font-size: 36px; 
        }
        &__text3{
            font-size: 34px; 
        }
        &__discord{ 
            img{ 
            width: 5.5rem; 
            height: 6rem;                 
            }
        }
        &__right{ 
            margin-left: -0.5rem; 
            margin-top: -2rem; 
            video{
                width: 60rem; 
                height: 60rem; 
            }
        }
        &__main{
            margin-top: 1rem; 
        }
        &__open{
            font-size: 40px;
			margin-bottom: -1.6rem; 
        }
        &__timer{
            font-size: 50px; 
            margin-top: -4.5rem; 
        }
        &__access{
            font-size: 34px;; 
        }
        &__button1{
            button{ 
            font-size: 34px; 
            width: 28rem; 
            height: 5.8rem; 
            }
        }
		&__button2{
			button{ 
			font-size: 34px; 
			width: 13rem; 
			height: 5.8rem; 
			border-radius: 45px; 
			margin-top: 1rem; 
			cursor: pointer; 
			}
		}
        &__matic{
            font-size: 21px; 
        }
        &__conditions{
            font-size: 23px; 
            margin-left: 16rem; 
            margin-right: 16rem; 
        }
        &__line{
            height: 4.5px; 
            width: 50%; 
        }
        &__portfolio{
            font-size: 31px; 
            margin-top: 1rem; 
        }
        &__artists {
            display: flex;
            flex-direction: column;
            text-align: center; 
            a{
            font-size: 5.6rem; 
            }
        }
        &__721--title{
            font-size: 42px; 
            margin-bottom: -3.5rem; 
            margin-top: -1rem; 
        }
        &__721--description{
            font-size: 25px;
            margin-top: 2rem; 
        }
        &__symbol1{
            width: 12rem; 
            height: auto; 
            margin-top: 0.8rem; 
        }
        &__symbol2{
            width: 24rem; 
            height: auto; 
            margin-left: -10rem;  
            margin-top: 5rem; 
        }
    }
}
@media (min-width: 2560px) {
#landingPage{
    &__header{
        display: flex; 
        flex-direction: row; 
        align-items: flex-start;
        margin-left: -1rem;
        margin-top: 4rem;  
        }
        &__left{
            width: 50%; 
            margin-top: 1rem; 
        }
        &__title{
            img{
            width: 64rem; 
            margin-left: -2.5rem; 
            } 
        }
        &__subtitle{ 
            font-size: 38px;
            margin-top: -4.5rem;  
            margin-left: 4rem; 
            margin-bottom: 6rem; 
        }
        &__text1{ 
            margin-top: -0.5rem; 
            font-size: 32px; 
            margin-bottom: 6rem; 
            p{
                margin-bottom: -1.5rem; 
            }
        }
        &__text1--top{
            margin-bottom: 4.5rem; 
        }
        &__text1--middle{
            margin-bottom: 4.5rem; 
        }
        &__text1--down{
            margin-bottom: 6rem; 
        }
        &__text2{ 
            font-size: 48px; 
        }
        &__text3{
            font-size: 44px; 
        }
        &__discord{ 
            img{ 
            width: 7.5rem; 
            height: 8rem;                 
            }
        }
        &__right{ 
            margin-left: -0.5rem; 
            margin-top: -2rem; 
            video{
                width: 80rem; 
                height: 80rem; 
            }
        }
        &__main{
            margin-top: 1rem; 
        }
        &__open{
            font-size: 56px;
			margin-bottom: -1.8rem; 
        }
        &__timer{
            font-size: 74px; 
            margin-top: -6rem; 
        }
        &__access{
            font-size: 48px;; 
        }
        &__button1{
            button{ 
            font-size: 48px; 
            width: 40rem; 
            height: 8rem; 
            border-radius: 70px; 
            }
        }
		&__button2{
			button{ 
			font-size: 48px; 
			width: 18rem; 
			height: 8rem; 
			border-radius: 70px; 
			margin-top: 1rem; 
			margin-bottom: 1rem; 
			cursor: pointer; 
			}
		}
        &__matic{
            font-size: 28px; 
        }
        &__conditions{
            font-size: 30px; 
            margin-left: 20rem; 
            margin-right: 20rem; 
        }
        &__line{
            height: 6.5px; 
            width: 50%; 
        }
        &__portfolio{
            font-size: 46px; 
            margin-top: 1rem; 
        }
        &__links{
            padding-top: 5rem; 
        }
        &__artists {
            display: flex;
            flex-direction: column;
            text-align: center; 
            a{
            font-size: 7.6rem; 
            }
        }
        &__721--title{
            font-size: 60px; 
            margin-bottom: -5rem; 
            margin-top: -2rem; 
        }
        &__721--description{
            font-size: 34px;
            margin-top: 2rem; 
        }
        &__symbol1{
            width: 16rem; 
            height: auto; 
            margin-top: 0.8rem; 
        }
        &__symbol2{
            width: 34rem; 
            height: auto; 
            margin-left: -15rem;  
            margin-top: 7rem; 
        }
    }
}
@media (min-width: 3456px) {
	#landingPage{
		&__header{
			display: flex; 
			flex-direction: row; 
			align-items: flex-start;
			margin-left: 2rem;
			margin-top: 6rem;  
			}
			&__left{
				width: 50%; 
				margin-top: 1rem; 
			}
			&__title{
				img{
				width: 90rem; 
				margin-left: -4.8rem; 
				} 
			}
			&__subtitle{ 
				font-size: 56px;
				margin-top: -6.5rem;  
				margin-left: 4rem; 
				margin-bottom: 6rem; 
			}
			&__text1{ 
				margin-top: -0.5rem; 
				font-size: 46px; 
				margin-bottom: 6rem; 
				p{
					margin-bottom: -2.5rem; 
				}
			}
			&__text1--top{
				margin-bottom: 6.5rem; 
			}
			&__text1--middle{
				margin-bottom: 6.5rem; 
			}
			&__text1--down{
				margin-bottom: 8rem; 
			}
			&__text2{ 
				font-size: 72px; 
			}
			&__text3{
				font-size: 65px; 
			}
			&__discord{ 
				img{ 
				width: 11rem; 
				height: 11.5rem;                 
				}
			}
			&__right{ 
				margin-left: -2rem; 
				margin-top: -3rem; 
				video{
					width: 110rem; 
					height: 110rem; 
				}
			}
			&__main{
				margin-top: 1rem; 
			}
			&__open{
				font-size: 84px;
				margin-bottom: -5rem; 
			}
			&__timer{
				font-size: 120px; 
				margin-top: -12rem; 
				margin-bottom: -4rem; 
			}
			&__access{
				font-size: 74px;; 
			}
			&__button1{
				button{ 
				font-size: 72px; 
				width: 58rem; 
				height: 12.5rem; 
				border-radius: 100px; 
				}
			}
			&__button2{
				button{ 
				font-size: 72px; 
				width: 26rem; 
				height: 12.5rem; 
				border-radius: 100px; 
				margin-top: 2rem; 
				margin-bottom: 5rem; 
				cursor: pointer; 
				}
			}
			&__matic{
				font-size: 46px; 
			}
			&__conditions{
				font-size: 46px; 
				margin-left: 26rem; 
				margin-right: 26rem; 
			}
			&__line{
				height: 12px; 
				width: 50%; 
				margin-top: 6rem;
				margin-bottom: 2rem; 
			}
			&__portfolio{
				font-size: 70px; 
				margin-top: 1rem; 
			}
			&__links{
				padding-top: 8rem; 
			}
			&__artists {
				display: flex;
				flex-direction: column;
				text-align: center; 
				a{
				font-size: 11.5rem; 
				}
			}
			&__721--title{
				font-size: 96px; 
				margin-bottom: -8rem; 
				margin-top: -5rem; 
			}
			&__721--description{
				font-size: 56px;
				margin-top: 3rem; 
			}
			&__symbol1{
				width: 26rem; 
				height: auto; 
				margin-top: 0.8rem; 
			}
			&__symbol2{
				width: 52rem; 
				height: auto; 
				margin-left: -25rem;  
				margin-top: 10rem; 
			}
		}
	}
@media (min-width: 3840px) {
#landingPage{
    &__header{
        display: flex; 
        flex-direction: row; 
        align-items: flex-start;
        margin-left: 2rem;
        margin-top: 6rem;  
        }
        &__left{
            width: 50%; 
            margin-top: 1rem; 
        }
        &__title{
            img{
            width: 100rem; 
            margin-left: -5.3rem; 
            } 
        }
        &__subtitle{ 
            font-size: 60px;
            margin-top: -6.5rem;  
            margin-left: 4rem; 
            margin-bottom: 6rem; 
        }
        &__text1{ 
            margin-top: -0.5rem; 
            font-size: 48px; 
            margin-bottom: 6rem; 
            p{
                margin-bottom: -2.5rem; 
            }
        }
        &__text1--top{
            margin-bottom: 6.5rem; 
        }
        &__text1--middle{
            margin-bottom: 6.5rem; 
        }
        &__text1--down{
            margin-bottom: 8rem; 
        }
        &__text2{ 
            font-size: 76px; 
        }
        &__text3{
            font-size: 68px; 
        }
        &__discord{ 
            img{ 
            width: 11.5rem; 
            height: 12rem;                 
            }
        }
        &__right{ 
            margin-left: -1.6rem; 
            margin-top: -3rem; 
            video{
                width: 120rem; 
                height: 120rem; 
            }
        }
        &__main{
            margin-top: 1rem; 
        }
        &__open{
            font-size: 86px;
			margin-bottom: -5rem; 
        }
        &__timer{
            font-size: 120px; 
            margin-top: -12rem; 
            margin-bottom: -4rem; 
        }
        &__access{
            font-size: 76px;; 
        }
        &__button1{
            button{ 
            font-size: 72px; 
            width: 60rem; 
            height: 12.5rem; 
            border-radius: 100px; 
            }
        }
		&__button2{
			button{ 
			font-size: 72px; 
			width: 26rem; 
			height: 12.5rem; 
			border-radius: 100px; 
			margin-top: 3rem; 
			margin-bottom: 6rem; 
			cursor: pointer; 
			}
		}
        &__matic{
            font-size: 48px; 
        }
        &__conditions{
            font-size: 48px; 
            margin-left: 26rem; 
            margin-right: 26rem; 
        }
        &__line{
            height: 12px; 
            width: 50%; 
			margin-top: 6rem;
			margin-bottom: 2rem; 
        }
        &__portfolio{
            font-size: 72px; 
            margin-top: 1rem; 
        }
        &__links{
            padding-top: 8rem; 
        }
        &__artists {
            display: flex;
            flex-direction: column;
            text-align: center; 
            a{
            font-size: 11.5rem; 
            }
        }
        &__721--title{
            font-size: 96px; 
            margin-bottom: -8rem; 
            margin-top: -5rem; 
        }
        &__721--description{
            font-size: 56px;
            margin-top: 3rem; 
        }
        &__symbol1{
            width: 26rem; 
            height: auto; 
            margin-top: 0.8rem; 
        }
        &__symbol2{
            width: 52rem; 
            height: auto; 
            margin-left: -25rem;  
            margin-top: 10rem; 
        }
    }
}
