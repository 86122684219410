#popup1Display{
    display: none; 
}
#absolute1{
    position: relative; 
}
#mintNFT{
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    width: 30%;
    margin-left: 33.5rem; 
    margin-top: -37rem; 
    background: white;
    position: absolute;  
    border: 1px solid black; 
    border-radius: 10px; 
    position: absolute;
    &__close{
        margin-top: 1rem; 
        margin-bottom: -2rem; 
        margin-left: 25rem;
        cursor: pointer; 
        i{
            color: #00dfe9;
        }
    }
    &__title{
        font-size: 18px; 
    }
    &__name{ 
        input{
        width: 15rem; 
        height: 2rem; 
        border: none;
        border-radius: 20px; 
        background-color: rgb(235, 235, 235); 
        padding-left: 1rem; 
        font-size: 16px; 
    } 
    }
    &__url{
        margin-top: 1rem;
        input{
        width: 15rem; 
        height: 2rem; 
        border: none; 
        border-radius: 20px; 
        background-color: rgb(235, 235, 235); 
        padding-left: 1rem; 
        font-size: 16px; 
        }
    }
    &__mint{
        input{
        background: transparent linear-gradient(90deg, #c6c5f5 30%, #00dfe9 100%) 0% 0% no-repeat padding-box;
        color: white; 
        border: none; 
        border-radius: 30px; 
        font-size: 18px; 
        width: 12rem; 
        height: 3rem; 
        margin-top: 2rem; 
        margin-bottom: 2rem; 
        cursor: pointer; 
        }
    }
}

@media (min-width: 320px) {
    #absolute{
        position: relative; 
    }
    #mintNFT{
        width: 90%;
        margin-left: 2rem; 
        margin-top: 119rem; 
        z-index: 1;
        &__close{
            margin-top: 1.5rem; 
            margin-bottom: -2rem; 
            margin-left: 32rem;
            font-size: 26px;
        }
        &__title{
            font-size: 26px;
            margin-bottom: 1rem; 
        }
        &__name{ 
            input{
            width: 25rem; 
            height: 4rem; 
            border-radius: 30px; 
            padding-left: 1.5rem; 
            font-size: 26px; 
        } 
        }
        &__url{
            margin-top: 1.5rem;
            input{
            width: 25rem; 
            height: 4rem; 
            border-radius: 30px; 
            padding-left: 1.5rem; 
            font-size: 26px; 
            }
        }
        &__mint{
            input{
            border-radius: 40px; 
            font-size: 28px; 
            width: 18rem; 
            height: 5rem; 
            margin-top: 3rem; 
            margin-bottom: 3rem;
            }
        }
    }
}
@media (min-width: 375px) {
    #mintNFT{
        width: 90%;
        margin-left: 2rem; 
        margin-top: 111rem; 
        z-index: 1;
        &__close{
            margin-top: 1.5rem; 
            margin-bottom: -2rem; 
            margin-left: 32rem;
            font-size: 26px;
        }
        &__title{
            font-size: 26px;
            margin-bottom: 1rem; 
        }
        &__name{ 
            input{
            width: 25rem; 
            height: 4rem; 
            border-radius: 30px; 
            padding-left: 1.5rem; 
            font-size: 26px; 
        } 
        }
        &__url{
            margin-top: 1.5rem;
            input{
            width: 25rem; 
            height: 4rem; 
            border-radius: 30px; 
            padding-left: 1.5rem; 
            font-size: 26px; 
            }
        }
        &__mint{
            input{
            border-radius: 40px; 
            font-size: 28px; 
            width: 18rem; 
            height: 5rem; 
            margin-top: 3rem; 
            margin-bottom: 3rem; 
            }
        }
    }
}
@media (min-width: 425px) {
    #mintNFT{
        width: 88%;
        margin-left: 2.4rem; 
        margin-top: 104.8rem; 
        z-index: 1;
        &__close{
            margin-left: 30rem;
            font-size: 24px;
        }
        &__title{
            font-size: 24px;
        }
        &__name{ 
            input{
            width: 23rem; 
            font-size: 22px; 
        } 
        }
        &__url{
            input{
            width: 23rem; 
            font-size: 22px; 
            }
        }
        &__mint{
            input{
            font-size: 24px; 
            width: 17rem; 
            height: 4.5rem; 
            margin-top: 2.5rem; 
            margin-bottom: 2.5rem; 
            }
        }
    }
}
@media (min-width: 576px) {   
    #mintNFT{
        width: 85%;
        margin-left: 2.6rem; 
        margin-top: 86rem; 
        z-index: 1;
        &__close{
            margin-left: 27rem;
            font-size: 18px;
        }
        &__title{
            font-size: 19px;
        }
        &__name{ 
            input{
            width: 20rem; 
            height: 3rem; 
            font-size: 16px; 
        } 
        }
        &__url{
            input{
                width: 20rem; 
                height: 3rem; 
                font-size: 16px;  
            }
        }
        &__mint{
            input{
            font-size: 20px; 
            width: 15rem; 
            height: 3.5rem; 
            margin-top: 2rem; 
            margin-bottom: 2rem; 
            }
        }
    }
}
@media (min-width: 768px) {
    #mintNFT{
        width: 65%;
        margin-left: 8.2rem; 
        margin-top: 86rem; 
        z-index: 1;
        &__close{
            margin-left: 27.5rem;
            font-size: 18px;
            margin-top: 1rem; 
        }
        &__title{
            font-size: 18px;
            margin-bottom: 0rem; 
        }
        &__name{ 
            input{
            width: 16rem; 
            height: 2.5rem; 
            font-size: 15px; 
            padding-left: 1rem; 
        } 
        }
        &__url{
            input{
                width: 16rem; 
                height: 2.5rem; 
                font-size: 15px;
                margin-top: -1rem; 
                margin-bottom: 0.2rem;  
                padding-left: 1rem;  
            }
        }
        &__mint{
            input{
            font-size: 18px; 
            width: 12rem; 
            height: 3rem; 
            margin-top: 1.5rem; 
            margin-bottom: 1.5rem; 
            }
        }
    }
}
@media (min-width: 992px) { 
    #mintNFT{
        width: 46%;
        margin-left: 16.8rem; 
        margin-top: 81.5rem; 
        z-index: 1; 
        &__close{
            margin-left: 25rem;
            font-size: 18px;
            margin-top: 1rem; 
        }
        &__title{
            font-size: 18px;
            margin-bottom: 0rem; 
        }
        &__name{ 
            input{
            width: 16rem; 
            height: 2.5rem; 
            font-size: 15px; 
            padding-left: 1rem; 
        } 
        }
        &__url{
            input{
                width: 16rem; 
                height: 2.5rem; 
                font-size: 15px;
                margin-top: -1rem; 
                margin-bottom: 0.2rem;  
                padding-left: 1rem;  
            }
        }
        &__mint{
            input{
            font-size: 18px; 
            width: 12rem; 
            height: 3rem; 
            margin-top: 1.5rem; 
            margin-bottom: 1.5rem; 
            }
        }
    }
}
@media (min-width: 1024px) { 
    #mintNFT{
        width: 44%;
        margin-left: 18rem; 
        margin-top: 45.5rem; 
        z-index: 1;  
        &__close{
            margin-left: 25rem;
            font-size: 18px;
            margin-top: 1rem; 
        }
        &__title{
            font-size: 18px;
            margin-bottom: 0rem; 
        }
        &__name{ 
            input{
            width: 16rem; 
            height: 2.5rem; 
            font-size: 15px; 
            padding-left: 1rem; 
        } 
        }
        &__url{
            input{
                width: 16rem; 
                height: 2.5rem; 
                font-size: 15px;
                margin-top: -1rem; 
                margin-bottom: 0.2rem;  
                padding-left: 1rem;  
            }
        }
        &__mint{
            input{
            font-size: 18px; 
            width: 12rem; 
            height: 3rem; 
            margin-top: 1.5rem; 
            margin-bottom: 1.5rem; 
            }
        }
    }
}
@media (min-width: 1200px) {
    #mintNFT{
        width: 42%;
        margin-left: 21.5rem; 
        margin-top: 46.3rem; 
        z-index: 1; 
        &__close{
            margin-left: 27rem;
            font-size: 20px;
            margin-top: 1rem; 
        }
        &__title{
            font-size: 20px;
            margin-bottom: 0.3rem; 
        }
        &__name{ 
            input{
            width: 16rem; 
            height: 3rem; 
            font-size: 17px; 
            padding-left: 1rem; 
        } 
        }
        &__url{
            input{
                width: 16rem; 
                height: 3rem; 
                font-size: 17px;
                margin-top: -0.5rem; 
                margin-bottom: 0.3rem;  
                padding-left: 1rem;  
            }
        }
        &__mint{
            input{
            font-size: 20px; 
            width: 13rem; 
            height: 3.5rem; 
            margin-top: 1.8rem; 
            margin-bottom: 1.5rem; 
            }
        }
    }
}
@media (min-width: 1400px) {
    #mintNFT{
        width: 40%;
        margin-left: 26.5rem; 
        margin-top: 50.2rem; 
        z-index: 1; 
        &__close{
            margin-left: 30rem;
            font-size: 24px;
            margin-top: 1rem; 
        }
        &__title{
            font-size: 21px;
            margin-bottom: 0.3rem; 
        }
        &__name{ 
            input{
            width: 18rem; 
            height: 3.5rem; 
            font-size: 20px; 
            padding-left: 1rem; 
        } 
        }
        &__url{
            input{
                width: 18rem; 
                height: 3.5rem; 
                font-size: 20px; 
                margin-top: -0.2rem; 
                margin-bottom: 0.2rem;  
                padding-left: 1rem;  
            }
        }
        &__mint{
            input{
            font-size: 22px; 
            width: 15rem; 
            height: 4rem; 
            margin-top: 1.8rem; 
            margin-bottom: 1.8rem; 
            }
        }
    }
}
@media (min-width: 1600px) {
    #mintNFT{
        width: 38%;
        margin-left: 31rem; 
        margin-top: 56.5rem; 
        z-index: 1; 
        &__close{
            margin-left: 33rem;
            font-size: 26px;
            margin-top: 1rem; 
        }
        &__title{
            font-size: 24px;
            margin-bottom: 0.3rem; 
        }
        &__name{ 
            input{
            width: 22rem; 
            height: 4rem; 
            font-size: 23px; 
            padding-left: 1.4rem; 
        } 
        }
        &__url{
            input{
                width: 22rem; 
                height: 4rem; 
                font-size: 23px; 
                margin-top: -0.2rem; 
                margin-bottom: 0.2rem;  
                padding-left: 1.4rem;  
            }
        }
        &__mint{
            input{
            font-size: 24px; 
            width: 17rem; 
            height: 4.5rem; 
            margin-top: 2rem; 
            margin-bottom: 1.8rem; 
            }
        }
    }
}
@media (min-width: 1800px) {
    #mintNFT{
        width: 38%;
        margin-left: 34.5rem; 
        margin-top: 62.8rem; 
        z-index: 1; 
        &__close{
            margin-left: 37rem;
            font-size: 29px;
            margin-top: 1.5rem; 
        }
        &__title{
            font-size: 27px;
            margin-bottom: 0.3rem; 
        }
        &__name{ 
            input{
            width: 23rem; 
            height: 4rem; 
            font-size: 25px; 
            padding-left: 1.4rem; 
        } 
        }
        &__url{
            input{
                width: 23rem; 
                height: 4rem; 
                font-size: 25px; 
                margin-top: -0.2rem; 
                margin-bottom: 0.2rem;  
                padding-left: 1.4rem;  
            }
        }
        &__mint{
            input{
            font-size: 28px; 
            width: 18rem; 
            height: 5rem; 
            margin-top: 2.5rem; 
            margin-bottom: 1.8rem; 
            }
        }
    }
}
@media (min-width: 1920px) {
    #mintNFT{
        width: 38%;
        margin-left: 37rem; 
        margin-top: 76rem; 
        z-index: 1;  
        &__close{
            margin-left: 39rem;
            font-size: 32px;
            margin-top: 1.5rem;
        }
        &__title{
            font-size: 30px;
            margin-bottom: 0.3rem; 
        }
        &__name{ 
            input{
            width: 25rem; 
            height: 4.5rem; 
            font-size: 28px; 
            padding-left: 1.4rem; 
        } 
        }
        &__url{
            input{
                width: 25rem; 
                height: 4.5rem; 
                font-size: 28px;  
                margin-top: -0.2rem; 
                margin-bottom: 0.2rem;  
                padding-left: 1.4rem;  
            }
        }
        &__mint{
            input{
            font-size: 32px; 
            width: 20rem; 
            height: 5.5rem; 
            margin-top: 2.8rem; 
            margin-bottom: 2rem; 
            }
        }
    }
}
@media (min-width: 2560px) {
    #mintNFT{
        width: 40%;
        margin-left: 48rem; 
        margin-top: 100.2rem; 
        z-index: 1;  
        &__close{
            margin-left: 56rem;
            font-size: 46px;
            margin-top: 1.5rem;
            margin-bottom: -4rem; 
        }
        &__title{
            font-size: 42px;
            margin-bottom: 0.8rem; 
        }
        &__name{ 
            input{
            width: 38rem; 
            height: 6.5rem; 
            font-size: 38px; 
            border-radius: 60px; 
            padding-left: 2rem; 
        } 
        }
        &__url{
            input{
                width: 38rem; 
                height: 6.5rem; 
                font-size: 38px;  
                border-radius: 60px; 
                margin-top: 0.5rem; 
                margin-bottom: 0.5rem;  
                padding-left: 2rem;  
            }
        }
        &__mint{
            input{
            font-size: 43px; 
            width: 28rem; 
            height: 7.5rem; 
            border-radius: 70px; 
            margin-top: 3rem; 
            margin-bottom: 2rem; 
            }
        }
    }
}
@media (min-width: 3456px) {
    #mintNFT{
        width: 40%;
        margin-left: 65rem; 
        margin-top: 144.5rem; 
        z-index: 1;
        &__close{
            margin-left: 77rem;
            font-size: 68px;
            margin-top: 2.5rem;
            margin-bottom: -7rem; 
        }
        &__title{
            font-size: 60px;
            margin-bottom: 1rem; 
        }
        &__name{ 
            input{
            width: 55rem; 
            height: 10rem; 
            font-size: 56px; 
            border-radius: 80px; 
            padding-left: 4rem; 
        } 
        }
        &__url{
            input{
                width: 55rem; 
                height: 10rem; 
                font-size: 56px; 
                border-radius: 80px; 
                padding-left: 4rem;
                margin-top: 1.5rem; 
                margin-bottom: 0.5rem;  
            }
        }
        &__mint{
            input{
            font-size: 68px; 
            width: 42rem; 
            height: 11.5rem; 
            border-radius: 90px; 
            margin-top: 6rem; 
            margin-bottom: 4rem; 
            }
        }
    }
}
@media (min-width: 3840px) {
    #mintNFT{
        width: 40%;
        margin-left: 71rem; 
        margin-top: 143.2rem; 
        z-index: 1;
        &__close{
            margin-left: 86rem;
            font-size: 68px;
            margin-top: 2.5rem;
            margin-bottom: -7rem; 
        }
        &__title{
            font-size: 62px;
            margin-bottom: 1rem; 
        }
        &__name{ 
            input{
            width: 58rem; 
            height: 10.5rem; 
            font-size: 62px; 
            border-radius: 80px; 
            padding-left: 4rem; 
        } 
        }
        &__url{
            input{
                width: 58rem; 
                height: 10.5rem; 
                font-size: 62px; 
                border-radius: 80px; 
                padding-left: 4rem;
                margin-top: 1.5rem; 
                margin-bottom: 0.5rem;  
            }
        }
        &__mint{
            input{
            font-size: 68px; 
            width: 42rem; 
            height: 11.5rem; 
            border-radius: 90px; 
            margin-top: 6rem; 
            margin-bottom: 4rem; 
            }
        }
    }
}

