@media (min-width: 320px) {
    #displayNone {
        display: none;
    }
    #absolute3{
        position: relative; 
    }
    #timeOut{
		display: flex; 
    	flex-direction: column; 
		text-align: center;
    	background: white;
    	position: absolute;  
    	border: 1px solid black; 
    	border-radius: 10px;
        width: 80%;
        margin-left: 2rem; 
        margin-top: 116.8rem;
		padding-left: 2rem; 
		padding-right: 2rem;  
        z-index: 1;
        &__title{
            font-size: 28px;
            margin-bottom: -0.5rem;
			margin-top: 1rem; 
        }
        &__text1{
            font-size: 24px;
			margin-bottom: -0.5rem; 
        }
        &__text2{
            font-size: 24px; 
        }
        &__button{
			button{
			background: transparent linear-gradient(90deg, #c6c5f5 30%, #00dfe9 100%) 0% 0% no-repeat padding-box;
        	color: white; 
        	border: none; 
        	font-size: 26px; 
            width: 18rem; 
            height: 5rem; 
            border-radius: 45px; 
			margin-top: 1.5rem ;
			margin-bottom: 2.5rem; 
            cursor: pointer; 
			}
		}
    }
}

@media (min-width: 375px) {
    #absolute3{
        position: relative; 
    }
    #timeOut{
		display: flex; 
    	flex-direction: column; 
		text-align: center;
    	background: white;
    	position: absolute;  
    	border: 1px solid black; 
    	border-radius: 10px;
        width: 80%;
        margin-left: 2rem; 
        margin-top: 110rem;
		padding-left: 2rem; 
		padding-right: 2rem;  
        z-index: 1;
        &__title{
            font-size: 26px;
            margin-bottom: -0.5rem;
			margin-top: 1rem; 
        }
        &__text1{
            font-size: 22px;
			margin-bottom: -0.5rem; 
        }
        &__text2{
            font-size: 22px; 
        }
        &__button{
			button{
			background: transparent linear-gradient(90deg, #c6c5f5 30%, #00dfe9 100%) 0% 0% no-repeat padding-box;
        	color: white; 
        	border: none; 
        	font-size: 26px; 
            width: 18rem; 
            height: 5rem; 
            border-radius: 45px; 
			margin-top: 1.5rem ;
			margin-bottom: 2.5rem; 
            cursor: pointer; 
			}
		}
    }
}

@media (min-width: 425px) {
    #absolute3{
        position: relative; 
    }
    #timeOut{
		display: flex; 
    	flex-direction: column; 
		text-align: center;
    	background: white;
    	position: absolute;  
    	border: 1px solid black; 
    	border-radius: 10px;
        width: 78%;
        margin-left: 2.4rem; 
        margin-top: 105.2rem;
		padding-left: 2rem; 
		padding-right: 2rem;  
        z-index: 1;
        &__title{
            font-size: 24px;
            margin-bottom: -0.5rem;
			margin-top: 1rem; 
        }
        &__text1{
            font-size: 20px;
			margin-bottom: -0.5rem; 
        }
        &__text2{
            font-size: 20px; 
        }
        &__button{
			button{
			background: transparent linear-gradient(90deg, #c6c5f5 30%, #00dfe9 100%) 0% 0% no-repeat padding-box;
        	color: white; 
        	border: none; 
        	font-size: 24px; 
            width: 18rem; 
            height: 4.5rem; 
            border-radius: 45px; 
			margin-top: 1.5rem ;
			margin-bottom: 2.5rem; 
            cursor: pointer; 
			}
		}
    }
}

@media (min-width: 576px) {
    #absolute3{
        position: relative; 
    }
    #timeOut{
		display: flex; 
    	flex-direction: column; 
		text-align: center;
    	background: white;
    	position: absolute;  
    	border: 1px solid black; 
    	border-radius: 10px;
        width: 74%;
        margin-left: 2.6rem;      
        margin-top: 86.2rem;
		padding-left: 2rem; 
		padding-right: 2rem;  
        z-index: 1;
        &__title{
            font-size: 20px;
            margin-bottom: -0rem;
			margin-top: 1rem; 
        }
        &__text1{
            font-size: 15px;
			margin-bottom: -0rem; 
        }
        &__text2{
            font-size: 15px; 
        }
        &__button{
			button{
			background: transparent linear-gradient(90deg, #c6c5f5 30%, #00dfe9 100%) 0% 0% no-repeat padding-box;
        	color: white; 
        	border: none; 
        	font-size: 19px; 
            width: 14rem; 
            height: 3.5rem; 
            border-radius: 45px; 
			margin-top: 1rem ;
			margin-bottom: 2rem; 
            cursor: pointer; 
			}
		}
    }
}

@media (min-width: 768px) {
    #absolute3{
        position: relative; 
    }
    #timeOut{
		display: flex; 
    	flex-direction: column; 
		text-align: center;
    	background: white;
    	position: absolute;  
    	border: 1px solid black; 
    	border-radius: 10px;
        width: 56.6%;
        margin-left: 8.2rem;    
        margin-top: 86.2rem;
		padding-left: 2rem; 
		padding-right: 2rem;  
        z-index: 1;
        &__title{
            font-size: 19px;
            margin-bottom: -0.5rem;
			margin-top: 0.5rem; 
        }
        &__text1{
            font-size: 14px;
			margin-bottom: 0.5rem; 
        }
        &__text2{
            font-size: 14px; 
        }
        &__button{
			button{
			background: transparent linear-gradient(90deg, #c6c5f5 30%, #00dfe9 100%) 0% 0% no-repeat padding-box;
        	color: white; 
        	border: none; 
        	font-size: 18px; 
            width: 13rem; 
            height: 3rem; 
            border-radius: 45px; 
			margin-top: 1.2rem ;
			margin-bottom: 1.5rem; 
            cursor: pointer; 
			}
		}
    }
}

@media (min-width: 992px) {
    #absolute3{
        position: relative; 
    }
    #timeOut{
		display: flex; 
    	flex-direction: column; 
		text-align: center;
    	background: white;
    	position: absolute;  
    	border: 1px solid black; 
    	border-radius: 10px;
        width: 42%;
        margin-left: 16rem; 
        margin-top: 79.8rem;
		padding-left: 2rem; 
		padding-right: 2rem;  
        z-index: 1;
        &__title{
            font-size: 20px;
            margin-bottom: -0.5rem;
			margin-top: 0.5rem; 
        }
        &__text1{
            font-size: 16px;
			margin-bottom: 0.5rem; 
        }
        &__text2{
            font-size: 16px; 
        }
        &__button{
			button{
			background: transparent linear-gradient(90deg, #c6c5f5 30%, #00dfe9 100%) 0% 0% no-repeat padding-box;
        	color: white; 
        	border: none; 
        	font-size: 20px; 
            width: 14rem; 
            height: 3.5rem; 
            border-radius: 45px; 
			margin-top: 1.2rem ;
			margin-bottom: 1.5rem; 
            cursor: pointer; 
			}
		}
    }
}

@media (min-width: 1024px) {
    #absolute3{
        position: relative; 
    }
    #timeOut{
		display: flex; 
    	flex-direction: column; 
		text-align: center;
    	background: white;
    	position: absolute;  
    	border: 1px solid black; 
    	border-radius: 10px;
        width: 42%;
        margin-left: 16.5rem; 
        margin-top: 39.5rem;
		padding-left: 2rem; 
		padding-right: 2rem;  
        z-index: 1;
        &__title{
            font-size: 20px;
            margin-bottom: -0.5rem;
			margin-top: 0.5rem; 
        }
        &__text1{
            font-size: 16px;
			margin-bottom: 0.5rem; 
        }
        &__text2{
            font-size: 16px; 
        }
        &__button{
			button{
			background: transparent linear-gradient(90deg, #c6c5f5 30%, #00dfe9 100%) 0% 0% no-repeat padding-box;
        	color: white; 
        	border: none; 
        	font-size: 21px; 
            width: 14.5rem; 
            height: 3.8rem; 
            border-radius: 45px; 
			margin-top: 1.2rem ;
			margin-bottom: 1.5rem; 
            cursor: pointer; 
			}
		}
    }
}

@media (min-width: 1200px) {
    #absolute3{
        position: relative; 
    }
    #timeOut{
		display: flex; 
    	flex-direction: column; 
		text-align: center;
    	background: white;
    	position: absolute;  
    	border: 1px solid black; 
    	border-radius: 10px;
        width: 36.8%;
        margin-left: 21.5rem; 
        margin-top: 42.5rem;
		padding-left: 2rem; 
		padding-right: 2rem;  
        z-index: 1;
        &__title{
            font-size: 22px;
            margin-bottom: -0.5rem;
			margin-top: 01rem; 
        }
        &__text1{
            font-size: 16px;
			margin-bottom: 0.5rem; 
        }
        &__text2{
            font-size: 16px; 
        }
        &__button{
			button{
			background: transparent linear-gradient(90deg, #c6c5f5 30%, #00dfe9 100%) 0% 0% no-repeat padding-box;
        	color: white; 
        	border: none; 
        	font-size: 21px; 
            width: 14.5rem; 
            height: 3.8rem; 
            border-radius: 45px; 
			margin-top: 1.4rem ;
			margin-bottom: 2rem; 
            cursor: pointer; 
			}
		}
    }
}

@media (min-width: 1400px) {
    #absolute3{
        position: relative; 
    }
    #timeOut{
		display: flex; 
    	flex-direction: column; 
		text-align: center;
    	background: white;
    	position: absolute;  
    	border: 1px solid black; 
    	border-radius: 10px;
        width: 36.8%;
        margin-left: 25.8rem; 
        margin-top: 46rem;
		padding-left: 2rem; 
		padding-right: 2rem;  
        z-index: 1;
        &__title{
            font-size: 25px;
            margin-bottom: -0rem;
			margin-top: 01rem; 
        }
        &__text1{
            font-size: 18px;
			margin-bottom: 0.8rem; 
        }
        &__text2{
            font-size: 18px; 
        }
        &__button{
			button{
			background: transparent linear-gradient(90deg, #c6c5f5 30%, #00dfe9 100%) 0% 0% no-repeat padding-box;
        	color: white; 
        	border: none; 
        	font-size: 24px; 
            width: 16rem; 
            height: 4.5rem; 
            border-radius: 45px; 
			margin-top: 1.6rem ;
			margin-bottom: 2rem; 
            cursor: pointer; 
			}
		}
    }
}

@media (min-width: 1600px) {
    #absolute3{
        position: relative; 
    }
    #timeOut{
		display: flex; 
    	flex-direction: column; 
		text-align: center;
    	background: white;
    	position: absolute;  
    	border: 1px solid black; 
    	border-radius: 10px;
        width: 36.8%;
        margin-left: 29.5rem; 
        margin-top: 50rem;
		padding-left: 2rem; 
		padding-right: 2rem;  
        z-index: 1;
        &__title{
            font-size: 28px;
            margin-bottom: -0rem;
			margin-top: 01rem; 
        }
        &__text1{
            font-size: 21px;
			margin-bottom: 0.5rem; 
        }
        &__text2{
            font-size: 21px; 
        }
        &__button{
			button{
			background: transparent linear-gradient(90deg, #c6c5f5 30%, #00dfe9 100%) 0% 0% no-repeat padding-box;
        	color: white; 
        	border: none; 
        	font-size: 26px; 
            width: 18rem; 
            height: 5rem; 
            border-radius: 45px; 
			margin-top: 1.6rem ;
			margin-bottom: 2rem; 
            cursor: pointer; 
			}
		}
    }
}

@media (min-width: 1800px) {
    #absolute3{
        position: relative; 
    }
    #timeOut{
		display: flex; 
    	flex-direction: column; 
		text-align: center;
    	background: white;
    	position: absolute;  
    	border: 1px solid black; 
    	border-radius: 10px;
        width: 36.8%;
        margin-left: 33.3rem; 
        margin-top: 56rem;
		padding-left: 2rem; 
		padding-right: 2rem;  
        z-index: 1;
        &__title{
            font-size: 30px;
            margin-bottom: -0rem;
			margin-top: 01rem; 
        }
        &__text1{
            font-size: 24px;
			margin-bottom: 0.5rem; 
        }
        &__text2{
            font-size: 24px; 
        }
        &__button{
			button{
			background: transparent linear-gradient(90deg, #c6c5f5 30%, #00dfe9 100%) 0% 0% no-repeat padding-box;
        	color: white; 
        	border: none; 
        	font-size: 30px; 
            width: 21rem; 
            height: 5.6rem; 
            border-radius: 45px; 
			margin-top: 1.6rem ;
			margin-bottom: 2rem; 
            cursor: pointer; 
			}
		}
    }
}

@media (min-width: 1920px) {
    #absolute3{
        position: relative; 
    }
    #timeOut{
		display: flex; 
    	flex-direction: column; 
		text-align: center;
    	background: white;
    	position: absolute;  
    	border: 1px solid black; 
    	border-radius: 10px;
        width: 36.8%;
        margin-left: 35.7rem; 
        margin-top: 69.5rem;
		padding-left: 2rem; 
		padding-right: 2rem;  
        z-index: 1;
        &__title{
            font-size: 34px;
            margin-bottom: -0.5rem;
			margin-top: 1rem; 
        }
        &__text1{
            font-size: 26px;
			margin-bottom: 0.5rem; 
        }
        &__text2{
            font-size: 26px; 
        }
        &__button{
			button{
			background: transparent linear-gradient(90deg, #c6c5f5 30%, #00dfe9 100%) 0% 0% no-repeat padding-box;
        	color: white; 
        	border: none; 
        	font-size: 34px; 
            width: 24rem; 
            height: 6rem; 
            border-radius: 45px; 
			margin-top: 1.6rem ;
			margin-bottom: 2.5rem; 
            cursor: pointer; 
			}
		}
    }
}

@media (min-width: 2560px) {
    #absolute3{
        position: relative; 
    }
    #timeOut{
		display: flex; 
    	flex-direction: column; 
		text-align: center;
    	background: white;
    	position: absolute;  
    	border: 1px solid black; 
    	border-radius: 10px;
        width: 38%;
        margin-left: 47.5rem; 
        margin-top: 89.6rem;
		padding-left: 2rem; 
		padding-right: 2rem;  
        z-index: 1;
        &__title{
            font-size: 42px;
            margin-bottom: -0.5rem;
			margin-top: 2rem; 
        }
        &__text1{
            font-size: 35px;
			margin-bottom: 0.5rem; 
        }
        &__text2{
            font-size: 35px; 
        }
        &__button{
			button{
			background: transparent linear-gradient(90deg, #c6c5f5 30%, #00dfe9 100%) 0% 0% no-repeat padding-box;
        	color: white; 
        	border: none; 
        	font-size: 44px; 
            width: 30rem; 
            height: 8rem; 
            border-radius: 60px; 
			margin-top: 2.5rem ;
			margin-bottom: 4.5rem; 
            cursor: pointer; 
			}
		}
    }
}

@media (min-width: 3456px) {
    #absolute3{
        position: relative; 
    }
    #timeOut{
		display: flex; 
    	flex-direction: column; 
		text-align: center;
    	background: white;
    	position: absolute;  
    	border: 1px solid black; 
    	border-radius: 10px;
        width: 40%;
        margin-left: 60rem;
        margin-top: 144rem;
		padding-left: 5rem; 
		padding-right: 5rem;  
        z-index: 1;
        &__title{
            font-size: 68px;
            margin-bottom: -1.5rem;
			margin-top: 2rem; 
        }
        &__text1{
            font-size: 46px;
			margin-bottom: 0.5rem; 
        }
        &__text2{
            font-size: 46px; 
        }
        &__button{
			button{
			background: transparent linear-gradient(90deg, #c6c5f5 30%, #00dfe9 100%) 0% 0% no-repeat padding-box;
        	color: white; 
        	border: none; 
        	font-size: 66px; 
            width: 46rem; 
            height: 11rem; 
            border-radius: 100px; 
			margin-top:3rem ;
			margin-bottom: 5rem; 
            cursor: pointer; 
			}
		}
    }
}

@media (min-width: 3840px) {
    #absolute3{
        position: relative; 
    }
    #timeOut{
		display: flex; 
    	flex-direction: column; 
		text-align: center;
    	background: white;
    	position: absolute;  
    	border: 1px solid black; 
    	border-radius: 10px;
        width: 40%;
        margin-left: 67rem;
        margin-top: 129rem;
		padding-left: 5rem; 
		padding-right: 5rem;  
        z-index: 1;
        &__title{
            font-size: 72px;
            margin-bottom: -1.5rem;
			margin-top: 2rem; 
        }
        &__text1{
            font-size: 55px;
			margin-bottom: 0.5rem; 
        }
        &__text2{
            font-size: 55px; 
        }
        &__button{
			button{
			background: transparent linear-gradient(90deg, #c6c5f5 30%, #00dfe9 100%) 0% 0% no-repeat padding-box;
        	color: white; 
        	border: none; 
        	font-size: 76px; 
            width: 50rem; 
            height: 13rem; 
            border-radius: 100px; 
			margin-top:3rem ;
			margin-bottom: 5rem; 
            cursor: pointer; 
			}
		}
    }
}